import Insurances from "../components/insurances/Insurances";
import "../css/Services.css";

// const ServicesInsurances = ({ servicesRef }) => {
//   return (
//     <div className="services-outer-container" ref={servicesRef}>
//       <div className="services-inner-container">
//         <div className="services-container">
//           <h1 className="services-title">Serviços</h1>
//           <h2 className="services-subtitle mg-bottom-sm">
//             Atendimentos online e presencial
//           </h2>
//           <p className="services-text text">Psicologia Clínica</p>
//           <p className="services-text text">Avaliação Psicológica</p>
//           <p className="services-text text">Teste Vocacional</p>
//           <p className="services-text text">Nutrição</p>
//           <p className="services-text text">Psiquiatria</p>
//           <p className="services-text text">Fonoaudiologia</p>
//         </div>
//         <div className="insurances-container">
//           <h1 className="insurances-title">Convênios</h1>
//           <div className="insurances">
//             <div className="insurance-container">
//               <p className="insurances-text text">AFEB Brasal</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Plan Assiste (MPF)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">AFFEGO</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Plan Assiste (MPM)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">ASETE (ASTE)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Plan Assiste (MPT)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">ASSEFAZ</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">PLAS/JMU (STM)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CAESAN</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Postal Saúde (ECT)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CARE PLUS</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">PROASA</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CASEC (CODEVASF)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Pró-Saúde (TJDFT)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CASEMBRAPA (EMBRAPA)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Pró-social (TRF)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CASSI</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">
//                 Real Grandeza (demais planos)
//               </p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">CNTI</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">
//                 Real Grandeza (Salvus e Salutem)
//               </p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">EMBRATEL - demais planos</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Saúde Caixa</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">FACEB</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">SERPRO</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">FAPES (BNDES)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">SIS Senado</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">FASCAL</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">STF-MED (STF)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Gama Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">TRE Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">GDF Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">TRT Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Interlife</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">TRT Saúde - Casal</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Life Empresarial</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">TST Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">OMINT Saúde</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">UNAFISCO (SINDIFISCO)</p>
//             </div>
//             <div className="insurance-container">
//               <p className="insurances-text text">Plan Assiste</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const ServicesInsurances = ({ servicesRef }) => {
  return (
    <div className="services-outer-container" ref={servicesRef}>
      <div className="services-inner-container">
        <div className="services-container">
          <h1 className="services-title">Serviços</h1>
          <h2 className="services-subtitle mg-bottom-sm">
            Atendimentos online e presencial
          </h2>
          <p className="services-text text">Psicologia Clínica</p>
          <p className="services-text text">Avaliação Psicológica</p>
          <p className="services-text text">Teste Vocacional</p>
          <p className="services-text text">Nutrição</p>
          <p className="services-text text">Psiquiatria</p>
        </div>
        <div className="insurances-container">
          <h1 className="insurances-title">Convênios</h1>
          <div className="insurances">
            <Insurances />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesInsurances;
