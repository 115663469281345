import "../css/Contact.css";

import whatsapp from "../images/whatsapp.png";

const Contact = ({ contactRef }) => {
  return (
    <div className="contact-container" ref={contactRef}>
      <h1 className="contact-title">Contato</h1>
      <div className="contact-inner-container">
        <div className="info-container">
          <p className="text contact-text">
            <span className="gothic-bold">
              SGAN 915, Conjunto G, Bloco C, sala 113 - Edifício Golden Office -
              Asa Norte - Brasília - Distrito Federal.
            </span>
          </p>
          <p className="text contact-text">
            <span className="gothic-bold">E-mail:</span> clinicaanda@gmail.com
          </p>
          <p className="text contact-text">
            <span className="gothic-bold">Telefone/WhatsApp:</span>{" "}
            <a
              className="link no-border"
              href="https://wa.me/5561999783367"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="whatsapp-button">
                <span>
                  (61) 99978-3367{"  "}
                  <img className="wa-logo" src={whatsapp} alt="Logo WhatsApp" />
                </span>
              </span>
            </a>
          </p>
          <p className="text contact-text">
            <span className="gothic-bold">Instagram:</span>{" "}
            <a
              className="link"
              href="https://www.instagram.com/clinicaanda/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @clinicaanda
            </a>
          </p>
          <p className="text contact-text">
            <span className="gothic-bold">Facebook:</span>{" "}
            <a
              className="link"
              href="https://www.facebook.com/clinicaanda"
              target="_blank"
              rel="noopener noreferrer"
            >
              clinicaanda
            </a>
          </p>
          <p className="text">
            <span className="gothic-bold">Responsabilidade técnica:</span>
          </p>
          <p className="text">Natália Custódio de Castro CRP 01/19338</p>
          <p className="text contact-text">
            Patrícia Fernandes de Castro Abrantes Ferrão CRP 01/23460
          </p>
          <h2 className="contact-subtitle contact-text-sm">
            horário de funcionamento
          </h2>
          <p className="text">
            <span className="gothic-bold">Segunda a sexta-feira:</span> 8h às
            21h
          </p>
          <p className="text">
            <span className="gothic-bold">Sábados, domingos e feriados:</span>{" "}
            Fechado
          </p>
        </div>
        <div className="map-container">
          <iframe
            title="Mapa da Asa Norte mostrando o endereço da Clínica Anda."
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3840.1590770107946!2d-47.903607284450274!3d-15.74272172588181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3991596e3cfd%3A0x8a0d608b41e8dc0a!2sCl%C3%ADnica%20de%20Psicologia%20e%20cuidados%20em%20sa%C3%BAde%20Anda!5e0!3m2!1sen!2sbr!4v1658508607856!5m2!1sen!2sbr"
            style={{ border: 0 }}
            height="100%"
            width="100%"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
