import { useState, useEffect, useRef } from "react";
import Carousel from "../components/Carousel";
import "../css/Space.css";

import photoA from "../images/foto-espaco-anda-01.webp";
import photoB from "../images/foto-espaco-anda-02.webp";
import photoD from "../images/foto-espaco-anda-04.webp";
import photoE from "../images/foto-espaco-anda-05.webp";
import photoF from "../images/foto-espaco-anda-06.webp";
import photoG from "../images/foto-espaco-anda-07.webp";
import photoH from "../images/foto-espaco-anda-08.webp";

import photoC1 from "../images/c01.webp";
import photoC2 from "../images/c02.webp";
import photoC3 from "../images/c03.webp";
import photoC4 from "../images/c04.webp";

const Space = ({ spaceRef, isDesktop }) => {
  const [isVisibleOne, setVisibleOne] = useState(false);
  const [isVisibleOneAlt, setVisibleOneAlt] = useState(false);
  const [isVisibleTwo, setVisibleTwo] = useState(false);
  const [isVisibleThree, setVisibleThree] = useState(false);
  const [isVisibleFour, setVisibleFour] = useState(false);
  const [isVisibleFive, setVisibleFive] = useState(false);

  // Refs
  const containerOne = useRef();
  const imageOneA = useRef();
  const imageOneB = useRef();

  const containerOneAlt = useRef();
  const imageOneAAlt = useRef();
  const imageOneBAlt = useRef();

  const containerTwo = useRef();
  const imageTwoA = useRef();
  const imageTwoB = useRef();

  const containerThree = useRef();
  const imageThreeA = useRef();
  const imageThreeB = useRef();

  const containerFour = useRef();
  const imageFourA = useRef();
  const imageFourB = useRef();

  const containerFive = useRef();

  const activateImg = (section) => {
    if (section === 1) {
      if (isVisibleOne) {
        return;
      }
      setVisibleOne(true);
      imageOneA.current.style.opacity = 1;
      setTimeout(() => {
        imageOneB.current.style.opacity = 1;
      }, "200");
    }
    if (section === 1.5) {
      if (isVisibleOneAlt) {
        return;
      }
      setVisibleOneAlt(true);
      imageOneBAlt.current.style.opacity = 1;
      setTimeout(() => {
        imageOneAAlt.current.style.opacity = 1;
      }, "200");
    }
    if (section === 2) {
      if (isVisibleTwo) {
        return;
      }
      setVisibleTwo(true);
      imageTwoA.current.style.opacity = 1;
      setTimeout(() => {
        imageTwoB.current.style.opacity = 1;
      }, "200");
    }
    if (section === 3) {
      if (isVisibleThree) {
        return;
      }
      setVisibleThree(true);
      imageThreeA.current.style.opacity = 1;
      setTimeout(() => {
        imageThreeB.current.style.opacity = 1;
      }, "200");
    }
    if (section === 4) {
      if (isVisibleFour) {
        return;
      }
      setVisibleFour(true);
      imageFourA.current.style.opacity = 1;
      setTimeout(() => {
        imageFourB.current.style.opacity = 1;
      }, "200");
    }
    if (section === 5) {
      if (isVisibleFive) {
        return;
      }
      setVisibleFive(true);
      containerFive.current.style.opacity = 1;
    }
  };

  useEffect(() => {
    const observerOne = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(1);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    if (containerOne.current) {
      observerOne.observe(containerOne.current);
    }

    const observerOneAlt = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(1.5);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    if (containerOneAlt.current) {
      observerOneAlt.observe(containerOneAlt.current);
    }

    const observerTwo = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(2);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    observerTwo.observe(containerTwo.current);

    const observerThree = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(3);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    observerThree.observe(containerThree.current);

    const observerFour = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(4);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    observerFour.observe(containerFour.current);

    const observerFive = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          activateImg(5);
        }
      },
      { rootMargin: "0px", threshold: 0.4 }
    );
    observerFive.observe(containerFive.current);
  }, []);

  return (
    <div className="space-container" ref={spaceRef}>
      <h1 className="space-title">Nosso espaço</h1>
      {isDesktop ? (
        <div
          ref={containerOne}
          className="space-images-container container-one"
        >
          <img
            ref={imageOneA}
            className="space-photo photo-oneA"
            src={photoB}
            alt="Foto do interior da clínica Anda mostrando dois sofás e um quadro ao fundo."
          />
          <div
            ref={imageOneB}
            className="space-text-container space-photo photo-oneB"
          >
            <p className="text description-text">
              A Clínica Anda está localizada na{" "}
              <span className="schoolbook-bold">
                SGAN 915, conjunto G, bloco C, sala 113 - Edifício Golden Office
                - Asa Norte - Brasília - Distrito Federal,
              </span>{" "}
              em um ambiente agradável e acolhedor.
            </p>
            <p className="text description-text">
              Possuímos salas amplas e aconchegantes para atendimento clínico
              adulto e infantil.
            </p>
            <p className="text description-text">
              Nosso espaço foi planejado para oferecer aos nossos pacientes
              privacidade e acolhimento.
            </p>
            <p className="text description-text">
              Nossos atendimentos são somente com hora marcada.
            </p>
          </div>
        </div>
      ) : (
        <div
          ref={containerOneAlt}
          className="space-images-container container-one container-one-alt"
        >
          <img
            ref={imageOneAAlt}
            className="space-photo space-photo-alt photo-oneA"
            src={photoB}
            alt="Foto do interior da clínica Anda mostrando dois sofás e um quadro ao fundo."
          />
          <div
            ref={imageOneBAlt}
            className="space-text-container space-photo space-photo-alt photo-oneB"
          >
            <p className="text description-text">
              A Clínica Anda está localizada na{" "}
              <span className="schoolbook-bold">
                SGAN 915, conjunto G, bloco C, sala 113 - Edifício Golden Office
                - Asa Norte, Brasília, Distrito Federal,
              </span>{" "}
              em um ambiente agradável e acolhedor.
            </p>
            <p className="text description-text">
              Possuímos salas amplas e aconchegantes para atendimento clínico
              adulto e infantil.
            </p>
            <p className="text description-text">
              Nosso espaço foi planejado para oferecer aos nossos pacientes
              privacidade e acolhimento.
            </p>
            <p className="text description-text">
              Nossos atendimentos são somente com hora marcada.
            </p>
          </div>
        </div>
      )}
      <div ref={containerTwo} className="space-images-container">
        <img
          ref={imageTwoA}
          className="space-photo photo-twoA"
          src={photoA}
          alt="Foto do interior da clínica Anda mostrando um sofá com duas almofadas."
        />
        <img
          ref={imageTwoB}
          className="space-photo photo-twoB"
          src={photoD}
          alt="Foto do interior da clínica Anda mostrando um corredor com paredes brancas e teto rosa."
        />
      </div>
      <div ref={containerThree} className="space-images-container">
        <img
          ref={imageThreeA}
          className="space-photo photo-threeA"
          src={photoE}
          alt="Foto do interior da clínica anda mostrando um corredor com uma mulher."
        />
        <img
          ref={imageThreeB}
          className="space-photo photo-threeB"
          src={photoF}
          alt="Foto do interior da clínica anda mostrando o balcão da recepção e uma mulher ao fundo."
        />
      </div>
      <div ref={containerFour} className="space-images-container">
        <img
          ref={imageFourA}
          className="space-photo photo-fourA"
          src={photoG}
          alt="Foto do interior da clínica Anda mostrando a recepção frontalmente."
        />
        <img
          ref={imageFourB}
          className="space-photo photo-fourB"
          src={photoH}
          alt="Foto do interior da clínica Anda mostrando cadeiras da sala de espera e uma mulher ao fundo."
        />
      </div>
      <Carousel
        imgs={[photoC1, photoC2, photoC3, photoC4]}
        carouselRef={containerFive}
      />
      <p className="text space-text">
        Projeto: Kanno Arquitetura |{" "}
        <a
          className="link"
          href="https://www.instagram.com/kannoarquitetura/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @kannoarquitetura
        </a>
      </p>
      <p className="text space-text">
        Fotos: Júlia Tótoli |{" "}
        <a
          className="link"
          href="https://www.instagram.com/juliatotoli.foto/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @juliatotoli.foto
        </a>
      </p>
    </div>
  );
};

export default Space;
