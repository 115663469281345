import "../css/Footer.css";

const Footer = ({ heroRef }) => {
  const heroScroll = () => {
    heroRef.current.scrollIntoView();
  };

  return (
    <div className="footer-outer-container">
      <div className="footer-inner-container">
        <h2 className="footer-title" onClick={heroScroll}>
          anda
        </h2>
        <p className="footer-credits">
          Criado por{" "}
          <a
            className="white-link"
            href="https://lucaslamonier.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lucas Lamonier
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Footer;
