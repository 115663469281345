/* eslint-disable array-callback-return */
import { useState } from "react";
import "../css/Professionals.css";
import Accordion from "../components/accordion/Accordion";

import professionalsJSON from "../assets/allprofessionals.json";

import photoClaudia from "../images/p-claudia_duim_alvarenga.webp";

const Professionals = ({ professionalsRef, isDesktop }) => {
  const psychologists = {};
  professionalsJSON.map((prof) => {
    if (prof.type === "psicologia") {
      prof.public.map((pub) => {
        if (!psychologists[pub]) {
          psychologists[pub] = [prof];
        } else {
          psychologists[pub] = [...psychologists[pub], prof];
        }
      });
    }
  });

  const [activeP, setActiveP] = useState(() => {
    return Object.keys(psychologists).map((_, i) => {
      if (i === 0) return true;
      return false;
    });
  });

  const toggleActive = (profI) => {
    setActiveP(() => {
      const newValue = !activeP[profI];
      const state = activeP.map((el) => el);
      state[profI] = newValue;
      return state;
    });
  };

  const nutritionists = [];
  professionalsJSON.map((prof) => {
    if (prof.type === "nutricao") {
      prof.public.map((pub) => {
        if (!nutritionists[pub]) {
          nutritionists[pub] = [prof];
        } else {
          nutritionists[pub] = [...nutritionists[pub], prof];
        }
      });
    }
  });

  const [activeN, setActiveN] = useState(() => {
    return Object.keys(nutritionists).map((_, i) => {
      if (i === 0) return true;
      return false;
    });
  });

  const toggleActiveN = (profI) => {
    setActiveN(() => {
      const newValue = !activeN[profI];
      const state = activeN.map((el) => el);
      state[profI] = newValue;
      return state;
    });
  };

  const psychiatrists = [];
  professionalsJSON.map((prof) => {
    if (prof.type === "psiquiatria") {
      prof.public.map((pub) => {
        if (!psychiatrists[pub]) {
          psychiatrists[pub] = [prof];
        } else {
          psychiatrists[pub] = [...psychiatrists[pub], prof];
        }
      });
    }
  });

  const [activePsy, setActivePsy] = useState(() => {
    return Object.keys(psychiatrists).map((_, i) => {
      if (i === 0) return true;
      return false;
    });
  });

  const admins = [];
  professionalsJSON.map((prof) => {
    if (prof.type === "admin") {
      prof.public.map((pub) => {
        if (!admins[pub]) {
          admins[pub] = [prof];
        } else {
          admins[pub] = [...admins[pub], prof];
        }
      });
    }
  });

  const [activeAdmin, setActiveAdmin] = useState(() => {
    return Object.keys(admins).map((_, i) => {
      if (i === 0) return true;
      return false;
    });
  });

  const toggleActivePsy = (profI) => {
    setActivePsy(() => {
      const newValue = !activePsy[profI];
      const state = activePsy.map((el) => el);
      state[profI] = newValue;
      return state;
    });
  };

  return (
    <div className="professionals-container" ref={professionalsRef}>
      <h1 className="professionals-title">Nossos profissionais</h1>
      <h2 className="accordion-title">Psicologia</h2>
      <Accordion
        data={psychologists}
        active={activeP}
        toggleActive={toggleActive}
        isDesktop={isDesktop}
      />
      <h2 className="accordion-title">Nutrição</h2>
      <Accordion
        data={nutritionists}
        active={activeN}
        toggleActive={toggleActiveN}
        isDesktop={isDesktop}
      />
      <h2 className="accordion-title">Psiquiatria</h2>
      <Accordion
        data={psychiatrists}
        active={activePsy}
        toggleActive={toggleActivePsy}
        isDesktop={isDesktop}
      />
      <h2 className="accordion-title">Equipe administrativa</h2>
      <div className="accordion-item">
        <div className="accordion-photo accordion-center">
          <img src={photoClaudia} alt={`Foto de Moça`} className={"p-white"} />
        </div>
        <div className="accordion-text accordion-center">
          <h2 className="p-name">Cláudia Alvarenga</h2>
          <p className="p-position">Secretária</p>
          {/* <p className="text p-credentials mg-bottom-sm">{data.credentials}</p>
          <p className="text mg-bottom-sm text-small">{data.approach}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Professionals;
