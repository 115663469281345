import { useState, useEffect } from "react";
import "../css/Description.css";

import photoH from "../images/foto-espaco-anda-03.webp";
import photoV from "../images/foto-espaco-anda-01.webp";

const Description = ({ isDesktop, descriptionRef }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="description-container" ref={descriptionRef}>
      <h1 className="description-title">Quem somos</h1>
      <div className="description-inner-container">
        <div className="description-description">
          <p className="text description-text">
            Há dez anos, a Clínica Anda começou sua jornada em Brasília,
            oferecendo serviços de Psicologia e de outras áreas da saúde.
          </p>
          <p className="text description-text">
            Hoje, a Anda faz parte de um sonho compartilhado pelas amigas,
            sócias e psicólogas Patrícia Ferrão e Natália Castro.
          </p>
          <p className="text description-text">
            A clínica conta com equipe técnica, conectada com a singularidade
            das pessoas, dedicada e comprometida com condutas baseadas em
            evidências científicas, valorizando o acolhimento ao sofrimento
            humano e a escuta empática em um processo de transparência desde o
            primeiro contato.
          </p>
          <p className="text description-text">
            De maneira ética, a Anda oferece atendimento especializado para a
            promoção e o cuidado com a saúde de forma integralizada. Nossos
            atendimentos são nas modalidades presencial ou
            {"  "}
            <em>on-line</em>, adaptadas às necessidades de cada paciente.
          </p>
        </div>
        {!isMobile && (
          <img
            className="description-img"
            src={isDesktop ? photoH : photoV}
            alt="Fotografia de dois sofás com quadros ao fundo"
          />
        )}
      </div>
    </div>
  );
};

export default Description;
