import "../css/Header.css";

import { slide as Menu } from "react-burger-menu";
import { useState } from "react";

const Header = ({
  isDesktop,
  headerRef,
  heroRef,
  descriptionRef,
  spaceRef,
  professionalsRef,
  servicesRef,
  contactRef,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollHero = () => {
    heroRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  const scrollDescription = () => {
    descriptionRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  const scrollSpace = () => {
    spaceRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  const scrollProfessionals = () => {
    professionalsRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  const scrollServices = () => {
    servicesRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  const scrollContact = () => {
    contactRef.current.scrollIntoView();
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header-container" ref={headerRef}>
      {!isDesktop && (
        <Menu right isOpen={menuOpen} onOpen={handleOpen}>
          <button
            onClick={scrollDescription}
            className="navigation-button burger-button"
          >
            • apresentação
          </button>
          <button
            onClick={scrollSpace}
            className="navigation-button burger-button"
          >
            • a clínica
          </button>
          <button
            onClick={scrollProfessionals}
            className="navigation-button burger-button"
          >
            • equipe
          </button>
          <button
            onClick={scrollServices}
            className="navigation-button burger-button"
          >
            • serviços
          </button>
          <button
            onClick={scrollContact}
            className="navigation-button burger-button"
          >
            • contato
          </button>
        </Menu>
      )}
      <div className="header">
        <h2 className="header-title navigation-link" onClick={scrollHero}>
          anda
        </h2>
        {isDesktop && (
          <div className="buttons-container">
            <button onClick={scrollDescription} className="navigation-button">
              apresentação
            </button>
            <button onClick={scrollSpace} className="navigation-button">
              a clínica
            </button>
            <button onClick={scrollProfessionals} className="navigation-button">
              equipe
            </button>
            <button onClick={scrollServices} className="navigation-button">
              serviços
            </button>
            <button onClick={scrollContact} className="navigation-button">
              contato
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
