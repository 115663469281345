import "../css/Hero.css";

import logo from "../images/logo-anda.png";

const Hero = ({ heroRef }) => {
	return (
		<div className='hero' ref={heroRef}>
			<div className='hero-container'>
				<img src={logo} alt='Logo da clínica Anda' className='logo-img' />
			</div>
		</div>
	);
};

export default Hero;
