const names = [
  "AFEB Brasal",
  "AFFEGO",
  "ASETE (ASTE)",
  "ASSEFAZ",
  "BACEN",
  "BNDES",
  "CAESAN",
  "CAPESESP",
  "CARE PLUS",
  "CASEC (CODEVASF)",
  "CASEMBRAPA (EMBRAPA)",
  "CASSI",
  "CNTI",
  "CONAB",
  "EMBRATEL - demais planos",
  "E-VIDA",
  "FACEB",
  "FAPES (BNDES)",
  "FASCAL",
  "FUSEX",
  "Gama Saúde",
  "GDF Saúde",
  "Interlife",
  "Life Empresarial",
  "OMINT Saúde",
  "Plan Assiste",
  "Plan Assiste (MPF)",
  "Plan Assiste (MPM)",
  "Plan Assiste (MPT)",
  "PLAS/JMU (ECT)",
  "Postal Saúde (ECT)",
  "PROASA",
  "Pró-Saúde (ECT)",
  "Pró-Social (TRF)",
  "Real Grandeza (Salvus e Salutem)",
  "SAMP/AGMP",
  "Saúde Caixa",
  "SERPRO",
  "SIS Senado",
  "STF-MED (STF)",
  "TRE Saúde",
  "TRT Saúde",
  "TRT Saúde - Casal",
  "TST Saúde",
  "UNAFISCO (SINDIFISCO)",
];

const Insurances = () => {
  const half = Math.floor(names.length / 2);

  const leftCol = names.slice(0, half + 1).map((n, i) => {
    return (
      <div className="insurance-container" key={"lc" + i}>
        <p className="insurances-text text">{n}</p>
      </div>
    );
  });

  const rightCol = names.slice(half + 1).map((n, i) => {
    return (
      <div className="insurance-container" key={"rc" + i}>
        <p className="insurances-text text">{n}</p>
      </div>
    );
  });

  return (
    <>
      <div>{leftCol}</div>
      <div>{rightCol}</div>
    </>
  );
};

export default Insurances;
