import { useState } from "react";
import "./Carousel.css";

const Carousel = ({ imgs, carouselRef }) => {
  const [active, setActive] = useState(0);

  const next = () => {
    if (active === imgs.length - 1) {
      setActive(0);
      return;
    }
    setActive((active) => active + 1);
  };

  const previous = () => {
    if (active === 0) {
      setActive(imgs.length - 1);
      return;
    }
    setActive((active) => active - 1);
  };

  const goTo = (n) => {
    setActive(n);
  };

  return (
    <div className="carousel-container" ref={carouselRef}>
      <img src={imgs[0]} alt="Fotografia do interior da Clínica Anda" />
      {imgs.map((el, i) => {
        return (
          <img
            src={el}
            className="carousel-img"
            style={{
              transform: `translateX(${(i - active) * 100}%)`,
            }}
            alt="Fotografia do interior da Clínica Anda"
            key={i}
          />
        );
      })}
      <div
        className=" carousel-controllers carousel-previous"
        onClick={previous}
      >
        <div>
          <h1>{"<"}</h1>
        </div>
      </div>
      <div className=" carousel-controllers carousel-next" onClick={next}>
        <div>
          <h1>{">"}</h1>
        </div>
      </div>
      <div className="dots-container">
        {imgs.map((_, i) => {
          return (
            <div
              className={`dots-dot ${i === active && "dots-dot-active"}`}
              onClick={() => goTo(i)}
              key={i}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
